<template>
<div>
 <div v-for="item in idslIst" :key="item.id">
     <a @click="open(item)"> {{ item }}</a>
 </div>
 <a-modal v-model:visible="modalVisible" title="详情" width="80%" footer="" :maskClosable="false"
    :bodyStyle="{ height: '70vh', overflow: 'auto', background: '#f0f2f5', padding: '0 24px', }" destroyOnClose centered>
    <detail v-model:flowInstanceId="flowInstanceId" type="myProcessed" v-model:flowInstanceTaskId="flowInstanceTaskId"
      @ok="handleOk" />
  </a-modal>
</div>
</template>

<script setup>
import { ref } from 'vue'
import detail from '../detail.vue'
const props = defineProps({
  idslIst:{
    type:Array,
    default:[]
  },
})
const flowInstanceId = ref('')
const flowInstanceTaskId = ref('')
const modalVisible = ref(false)
const open = (item) => {
  modalVisible.value = true
  flowInstanceId.value = item
}
</script>

<style lang="less" scoped>

</style>